<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <!-- <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{msg}}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color:white;">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar> -->

    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title
                  >Quote
                  <v-spacer></v-spacer>
                   

                  <v-btn icon color="info" @click="prevPage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon color="info" @click="nextPage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    
                  
                    <v-col cols="12" sm="3" md="2">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Name of the Company"
                        
                        disabled
                        outlined
                        dense
                        v-model="customer.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        ref="contactPerson"
                        color="black"
                        label="Contact Person"
                        outlined
                        disabled
                        dense
                        v-model="customer.contactPerson"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="email"
                        color="black"
                        label="Email ID"
                       
                        outlined
                        disabled
                        dense
                        v-model="customer.email"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        disabled
                        ref="phone"
                        color="black"
                        label="Phone Number"
                        outlined
                      
                        dense
                        v-model="customer.phone"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        disabled
                        ref="fax"
                        color="black"
                        label="Fax"
                        outlined
                        dense
                        v-model="customer.fax"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Sales Rep."
                        outlined
                        dense
                        disabled
                        v-model="agentCode"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

          <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span>
                  </v-row> -->

                  

                  <!-- <v-row class="ml-1 mt-4" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span>
                  </v-row> -->

                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="reference"
                        color="black"
                        label="Reference"
                        outlined
                        dense
                        v-model="customer.reference"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                     <v-select
                        disabled
                        :items="qtTypes"
                        ref="type"
                        color="black"
                        label="Type"
                        outlined
                        item-text="type"
                        item-value="_id"
                        v-model="customer.typeId"
                        dense
                      ></v-select>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        disabled
                        :items="qtStock"
                        ref="type"
                        color="black"
                        label="Stock"
                        outlined
                        item-text="stock"
                        item-value="_id"
                        v-model="customer.stockId"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="Shape"
                        color="black"
                        label="Shape"
                        outlined
                        dense
                        v-model="customer.shape"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="width"
                        color="black"
                        label="Width"
                        outlined
                        dense
                        v-model="customer.width"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="length"
                        color="black"
                        label="Length"
                        outlined
                        dense
                        v-model="customer.lenght"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                       <v-text-field
                        disabled
                        ref="colors"
                        color="black"
                        label="Colors"
                        outlined
                        dense
                        v-model="customer.colors"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="copy"
                        color="black"
                        label="Copies"
                        outlined
                        dense
                        v-model="customer.copy"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                    <v-select
                         disabled
                        :items="qtOptions"
                        ref="type"
                        color="black"
                        label="Options"
                        multiple
                        outlined
                        item-text="options"
                        item-value="_id"
                        v-model="customer.optionsId"
                        dense
                      ></v-select>
                      </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                  
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="platecharge"
                        color="black"
                        label="Plate Charge"
                        outlined
                        dense
                        v-model="customer.plateCharge"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="atWork"
                        color="black"
                        label="Art Work"
                        outlined
                        dense
                        v-model="customer.atWork"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        disabled
                        ref="copyChange"
                        color="black"
                        label="Copy Change"
                        outlined
                        dense
                        v-model="customer.copyChange"
                      ></v-text-field>
                      </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="colorChange"
                        color="black"
                        label="Color Change"
                        outlined
                        dense
                        disabled
                        v-model="customer.colorChange"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="colorMatch"
                        color="black"
                        label="Color Match"
                        outlined
                        dense
                        v-model="customer.colorMatch"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="die"
                        color="black"
                        label="Die Charge"
                        outlined
                        dense
                        v-model="customer.die"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        :items="qtQuantity"
                        disabled
                        color="black"
                        label="Select Qty(k=1000)"
                        outlined
                        item-text="name"
                        item-value="value"
                        
                        v-model="customer.baseQuantity"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="quantity"
                        color="black"
                        disabled
                        outlined
                        dense
                        v-model="customer.quantity"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="quantity1"
                        color="black"
                        disabled
                        outlined
                        dense
                        v-model="customer.quantity1"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="quantity2"
                        color="black"
                        disabled
                        outlined
                        dense
                        v-model="customer.quantity2"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        color="black"
                        label="Price"
                        outlined
                        dense
                       
                      ></v-text-field>
                    </v-col>
                    
                     <v-col md="3">
                      <v-text-field
                        ref="price"
                        color="black"
                        disabled
                        outlined
                        dense
                        v-model="customer.price"
                      ></v-text-field>
                    </v-col>
                     <v-col md="3">
                      <v-text-field
                        ref="price1"
                        color="black"
                        disabled
                        outlined
                        dense
                        v-model="customer.price1"
                      ></v-text-field>
                    </v-col>
                     <v-col md="3">
                      <v-text-field
                        ref="price2"
                        color="black"
                        disabled
                        outlined
                        dense
                        v-model="customer.price2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-4" v-if="notes.length>0">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="12">
                          <v-text-field
                            ref="Notes"
                            color="black"
                            label="Note"
                            disabled
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 4%"
      right
      width="55"
    >
      <v-list nav dense>
       
        <!-- <v-list-item link title="Pdf">
          <v-list-item-icon>
             <a
              target="blank"
              :href="
                'http://13.127.171.21/pdf/generate/Customer_Details/?customerId=' + $route.params.id
                 
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
         <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="emailDialog = true"
              >mdi-gmail</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="emailDialog" max-width="800">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                ABB Labels
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="sendfrom"
                    label="Send From"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="sendto"
                    label="Send To"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-textarea outlined dense v-model="message" label="Message">
                  </v-textarea>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="emailDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="sendMail">
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <!-- <v-list-item
          link
          :to="'/editQuote/' + $route.params.id"
          title="Edit"
        >
          <v-icon color="primary" dark> mdi-pencil </v-icon>

          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
//import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      companyRules: [
        (v) => !!v || "Company Name is required",
        (v) =>
          (v && v.length <= 50) ||
          "Company Name must be less than 50 characters",
      ],
      numberrules: [
        // (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [(v) => !!v || "Phone is required"],
      customer: {
        id: null,
        companyName: null,
        contactPerson: null,
        email: null,
        nature: null,
        address: null,
        city: null,
        stateId: null,
        countryId: null,
        zip: null,
        phone: null,
        fax: null,
        
        accountNo: null,
        status: "Active",
        description: null,
        shape:null,
        reference:null,
        width:null,
        length:null,
        colors:null,
        plateCharge:null,
        atWork:null,
        copyChange:null,
        colorChange:null,
        colorMatch:null,
        die:null,
        baseQuantity:null,
        quantity:null,
        quantity1:null,
        quantity2:null,
        price:null,
        price1:null,
        price2:null,
        optionsId:[],
        stockId:null,
        typeId:null,

      },
      notes: [
        {
          note: null,
        },
      ],
      agentCode: null,
      emailDialog:false,
      sendfrom: null,
      sendto: null,
      sendTo:[],
      message: null,
      subject: null,
     
      showsnackbar: false,
      msg: null,
      ServerError: false,
      validateInput: null,
      timeout: 5000,
      appLoading: false,
      msg1: null,
      userData: [],
      allStatus: [],
      terms: [],
      states: [],
      qtTypes:[],
      qtStock:[],
      qtOptions:[],
      qtQuantity:[
        { name: "Quantity: 1 K", value: 1 },
        { name:  "Quantity: 2 K", value: 2 },
        { name:  "Quantity: 3 K", value: 3 },
        { name:  "Quantity: 5 K", value: 5 },
        { name:  "Quantity: 10 K", value: 10 },
        { name:  "Quantity: 15 K", value: 15 },
        { name:  "Quantity: 20 K", value: 20 },
        { name:  "Quantity: 30 K", value: 30},
        { name:  "Quantity: 50 K", value: 50 },
        { name:  "Quantity: 100 K", value: 100 },

      ],
      menu1: false,
      menu3: false,
      menu2: false,
      quantityId:null,
    };
  },

  beforeMount() {
    this.getCustomer();
    // this.stateList();
    // this.termList();
    // this.statusList();
    this.quoteType();
    this.quoteStock();
    this.quoteOptions();
    // this.quoteQunatity();
  },
  methods: {
    nextPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quote/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          quoteid: this.$route.params.id,
          type: "previous",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Quote/" + response.data.data._id);
            this.getCustomer();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    prevPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quote/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          quoteid: this.$route.params.id,
          type: "next",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Quote/" + response.data.data._id);
            this.getCustomer();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) this.editCustomer();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
      quoteType() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/type/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtTypes = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteStock() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/stock/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtStock = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteOptions() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/options/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtOptions = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteQunatity() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quote/quantity",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtQuantity = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quote/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.customer = response.data.data;
            var agCode = '';
            var agUcode = '';
            if(response.data.data.created_by)
              agCode = response.data.data.created_by.agentCode
            if(response.data.data.updated_by)
              agUcode = response.data.data.updated_by.agentCode
            this.agentCode = agCode+'/'+agUcode;
            this.sendto = this.customer.email;
            this.subject = "ABB Label - Quote Information for " + this.customer.companyName;
            if (this.customer.notes) 
            this.notes = this.customer.notes;
            
                (this.customer.initialDate = this.ChangeFormateDate(
                  response.data.data.initialDate
                ));
            this.customer.lastDate = this.ChangeFormateDate(
              response.data.data.lastDate
            );
            this.customer.birthDate = this.ChangeFormateDate(
              response.data.data.birthDate
            );
            this.getProfile();

          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
           if(response.data.data.email)
           this.sendfrom = response.data.data.email;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    sendMail() {
     this.emailDialog = false;
   
     this.sendTo = (this.sendto).split(",");
      axios({
        method: "POST",
        url: "/send/email",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          emailType: 'customer',
          id:this.$route.params.id,
          emailTo: this.sendTo,
          emailFrom:this.sendfrom,
          subject:this.subject,
          message:this.message,
        },
      })
        .then((response) => {
          this.appLoading = false;
          console.log(response.data.msg);
          this.message = '';
         
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
     
    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    termList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/term/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.terms = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    statusList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/customer/status",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.allStatus = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    editCustomer() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/customer/edit/" + this.$route.params.id,
        data: this.customer,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.getCustomer();
            // this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    ChangeFormateDate(oldDate) {
      if (oldDate) {
      var newdateFormat = oldDate.toString().split("T");
      return newdateFormat[0];
      }
    },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: grey !important;
}

.v-text-field input {
  color: grey !important;
}
.v-text-field__slot textarea {
  color: grey !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
a {
  text-decoration: none !important;
}
</style>